import React from 'react';
import TechnologyCard from './Card';


const TechnologyList = ({ pages }) => (
  <div className="research-list">
    { pages.map(({ node: page }, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <TechnologyCard data={ page } key={ key } />
    )) }
  </div>
);

export default TechnologyList;
