import React from 'react';

import Img from 'gatsby-image';

const TechnologyCard = ({ data }) => {
  const featuredImg = data?.frontmatter?.image?.childImageSharp.fluid;

  return (
    <div className="research-card">
      { !!featuredImg && (
        <Img className="research-card__img" fluid={ featuredImg } />
      ) }
      <div className="research-card__content">
        <div className="research-card__content__text">
          <h2 className="research-card__title">
            <a href={ data.fields.slug }>{ data.frontmatter.title }</a>
          </h2>
          <p className="research-card__copy">
            { data.frontmatter.description }
          </p>
        </div>

        <a className="research-card__link" href={ data.fields.slug }>
          Read More
        </a>
      </div>

    </div>
  );
};

export default TechnologyCard;
