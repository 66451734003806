import React from 'react';

import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';
import TechnologyList from 'Components/technology/List';
import SEO from 'Components/SEO';

export default ({ data }) => {
  const { edges: pages } = data.allMarkdownRemark;

  return (
    <PageWrapper>
      <SEO title="Technology" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">Technology</h1>
        <TechnologyList pages={ pages } />
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: {
      frontmatter: {
        templateKey: {eq: "technology"},
      },
    }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`;
